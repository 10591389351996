import Header from './Component/Header';
import Form from './Component/Form';
import { useState } from 'react';
import TodoList from './Component/TodoList';
function App() {
  const[todo, setTodo] = useState('')
  const [todoList, setTodoList] = useState([])
  return (
    <div className="App">
      <Header/>
      <Form todo={todo} setTodo={setTodo} todoList={todoList} setTodoList={setTodoList}/>
      <TodoList setTodoList={setTodoList} todoList={todoList} />
    </div>
  );
}

export default App;
